import React, { useEffect } from 'react';
// import Contact from './contact';
// import Services from './services';
import Img from './img';
import Acceuil from './Acceuil';
import Avis from './Avis';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


import '../App.css'

const Home = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <><div className='bg-gray-100 mb-7'>
            <Img />
            <Acceuil />
            <Avis />
        </div><div className="fixed bottom-10 right-[30px]">
                <div className="relative flr">
                    <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value); }}>
                        <option value="en" className='text-lg'>English</option>
                        <option value="ar" className='text-lg'>Arabic</option>
                        <option value="fr" className='text-lg'>French</option>
                        <option value="sp" className='text-lg'>Espagnol</option>
                    </select>
                </div>
            </div></>
    );
}

export default Home;
