import React from 'react';


import second from '../img/customers.png'
import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';
const Img = () => {
    return (
        // <div>
        <div className="bg-coverr bg-center mt-[100px] bg-imagee">

            <img src={hero1} alt="" className='circle-image ml-11' data-aos="fade-left" />
            <img src={hero2} alt="" className='circle-image ml-9 mt-10' data-aos="fade-left" />
            <div className='flex justify-end mr-11' data-aos="fade-right">
                <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
                <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
            </div>
        </div >
        // </div>
    );
}

export default Img;
