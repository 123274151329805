import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { PacmanLoader } from 'react-spinners';
import "../index.css"
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';
import Swal from 'sweetalert2';

const Form = () => {

  const { t } = useTranslation();


  const [formData, setFormData] = useState({
    gender: 'male',
    age: '',
    height: '',
    heightUnit: 'cm',
    weight: '',
    weightUnit: 'kg',
    activityLevel: 'sédentaire',
    weightGoals: ''
  });


  const activityLevels = ['sédentaire', 'modérément_actif', 'actif', 'très_actif'];
  const [loading, setLoading] = useState(false);



  const calculeCalories = (formData) => {
    const { gender, age, height, heightUnit, weight, weightUnit, activityLevel, weightGoals } = formData;

    const heightInCm = heightUnit === 'cm' ? height : height * 30.48;
    const weightInKg = weightUnit === 'kg' ? weight : weight * 0.453592;

    const bmr = gender === 'male'
      ? 88.362 + (13.397 * weightInKg) + (4.799 * heightInCm) - (5.677 * age)
      : 447.593 + (9.247 * weightInKg) + (3.098 * heightInCm) - (4.330 * age);

    const activityMultipliers = {
      sédentaire: 1.2,
      modérément_actif: 1.55,
      actif: 1.725,
      très_actif: 1.9,
    };
    const tdee = bmr * activityMultipliers[activityLevel];
    const adjustedCalories = weightGoals ? tdee + parseInt(weightGoals) : tdee;

    return adjustedCalories;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const calculatedCalories = calculeCalories(formData);

    setTimeout(() => {
      setLoading(false);
      Swal.fire(`Calculated Calories: ${calculatedCalories.toFixed(2)} kcal`);
    }, 2000);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  return (

    <>
      <div className="bg-cover bg-center mt-[100px] bg-image">
        <div className="grid justify-items-center relative top-24 ">
          <p className="contact font-bold  text-8xl sm:text-9xl ">{t('Calories')} </p>
        </div>
        <img src={hero1} alt="" className='circle-image ml-11' data-aos="fade-left" />
        <img src={hero2} alt="" className='circle-image ml-9 mt-10' data-aos="fade-left" />
        <div className='flex justify-end mr-11' data-aos="fade-right">
          <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
          <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
        </div>
      </div>

      <div className=' p-2' style={{ position: 'relative' }}>
        <div className="position-relative">
          {loading && (
            <div className="loader-overlay">
              <PacmanLoader color="#83BB33" />
              <p> {t('Calculating your calories...')} </p>
            </div>
          )}</div>
        <form onSubmit={handleSubmit} className="container mt-5">
          {/* Step 1: Gender and Age */}
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="card p-5">
                <div className="number-circle">1</div>
                <label className="form-label">{t('À quel genre appartenez-vous? ')}</label>
                <div className="btn-group d-flex">
                  <button
                    type="button"
                    className={`btn ${formData.gender === 'male' ? 'btn-success' : 'btn-light'} flex-grow-1`}
                    onClick={() => handleChange({ target: { name: 'gender', value: 'male' } })}
                  >
                    {t('Homme')}
                  </button>
                  <button
                    type="button"
                    className={`btn ${formData.gender === 'female' ? 'btn-success' : 'btn-light'} flex-grow-1 ml-2`}
                    onClick={() => handleChange({ target: { name: 'gender', value: 'female' } })}
                  >
                    {t('Femme')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card p-5">
                <div className="number-circle">2</div>
                <label className="form-label">{t('Quel âge avez-vous?')}</label>
                <input
                  min={1}
                  type="number"
                  className="form-control"
                  name="age"
                  value={formData.age}
                  onChange={handleChange} />
              </div>
            </div>
          </div>

          {/* Step 2: Height and Weight */}
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="card p-5">
                <div className="number-circle">3</div>
                <label className="form-label">{t('Combien mesurez-vous?')}</label>
                <div className="input-group">
                  <input
                    min={100}
                    type="number"
                    className="form-control"
                    name="height"
                    placeholder="175 "
                    value={formData.height}
                    onChange={handleChange} />
                </div>
                <div className="d-flex mt-3">
                  <button
                    type="button"
                    className={`btn ${formData.heightUnit === 'cm' ? 'btn-success' : 'btn-light'} flex-grow-1`}
                    onClick={() => handleChange({ target: { name: 'heightUnit', value: 'cm' } })}
                  >
                    {t('cm')}
                  </button>
                  <button
                    type="button"
                    className={`btn ${formData.heightUnit === 'ft/in' ? 'btn-success' : 'btn-light'} flex-grow-1 ml-2`}
                    onClick={() => handleChange({ target: { name: 'heightUnit', value: 'ft/in' } })}
                  >
                    {t('ft/in')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card p-5">
                <div className="number-circle">4</div>
                <label className="form-label">{t('Combien pesez-vous?')}</label>
                <div className="input-group">
                  <input
                    min={1}
                    type="number"
                    className="form-control"
                    name="weight"
                    placeholder="70 "
                    value={formData.weight}
                    onChange={handleChange} />
                </div>
                <div className="d-flex mt-3">
                  <button
                    type="button"
                    className={`btn ${formData.weightUnit === 'kg' ? 'btn-success' : 'btn-light'} flex-grow-1`}
                    onClick={() => handleChange({ target: { name: 'weightUnit', value: 'kg' } })}
                  >
                    {t('kg')}
                  </button>
                  <button
                    type="button"
                    className={`btn ${formData.weightUnit === 'lbs' ? 'btn-success' : 'btn-light'} flex-grow-1 ml-2`}
                    onClick={() => handleChange({ target: { name: 'weightUnit', value: 'lbs' } })}
                  >
                    {t('livres')}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Step 3: Activity Level */}
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="card p-5">
                <div className="number-circle">5</div>
                <label className="form-label">{t('ft/in')}</label>
                <div className="d-flex">
                  {activityLevels.map((level) => (
                    <button
                      key={level}
                      type="button"
                      className={`btn ${formData.activityLevel === level ? 'btn-success' : 'btn-light'} flex-grow-1 mr-2`}
                      onClick={() => handleChange({ target: { name: 'activityLevel', value: level } })}
                    >
                      {level}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>


          {/* Step 4: Weight Goals */}
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="card p-5">
                <div className="number-circle">6</div>
                <label className="form-label">{t('Quel est votre objectif de poids?')}</label>
                <input
                  min={1}
                  type="number"
                  className="form-control"
                  name="weightGoals"
                  placeholder="75"
                  value={formData.weightGoals}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="btn btn-lg btn-slim text-white"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  letterSpacing: 'normal',
                  background: '#195A00'
                }}
              >
                {t('calculer mes calories')}
              </button>
            </div>
          </div>
        </form>








      </div>

      <div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value) }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div>
    </>
  );
}
export default Form;


//   try {
//     setLoading(true); // Set loading state to true
//     const response = await ChatGPTService(formData);

//     if (response && response.chatGptResponse) {
//       setCaloriesResponse(response.chatGptResponse);
//       setShowMessageBox(true);
//     } else {
//       throw new Error('Invalid response from the server');
//     }
//   } catch (error) {
//     console.error('Error:', error);
//     alert('Failed to fetch data from the server');
//   } finally {
//     setLoading(false); // Set loading state to true
//   }
// };


// {
//   showMessageBox && (
//     <div className="message-box-overlay">
//       <div className="message-box card bg-gradient" style={{ padding: '30px', position: 'relative', maxWidth: '600px', borderRadius: '15px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)' }}>
//         <button className="close-button" onClick={() => setShowMessageBox(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
//           &times;
//         </button>
//         <div className="wrapper">
//           <div className="card-body d-flex flex-column align-items-center">
//             <img src='/white-vopa.jpeg' alt="img" style={{ width: '100%', maxWidth: '500px', marginBottom: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} />
//             <h2 className="text-center" style={{ fontSize: '36px', marginBottom: '15px', color: '#262626', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
//               🍽️ Your Calorie Report is Ready!
//             </h2>
//             <p className="text-center" style={{ fontSize: '22px', color: '#262626', maxWidth: '500px', margin: '0 auto', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}>
//               Exciting news! Your personalized calorie calculation is ready to savor:
//               <br />
//               You've indulged in approximately <strong>{caloriesResponse.choices[0].message.content}</strong> calories. Keep enjoying your journey to a healthier you!
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

