import React, { useState, useEffect } from 'react';
import Aos from 'aos';

import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';


import { useTranslation } from "react-i18next";
import i18n from "../i18n";




const FAQ = () => {

  const { t } = useTranslation();

  const data = {
    title: t('FAQ')
  }

  const faqData = [
    {
      question: t("Qu'est-ce que VOPA ?"),
      answer: t('VOPA est un restaurant'),
    },
    {
      question: t('Quels types de plats'),
      answer: t('Notre menu comprend'),
    },
    // Add more FAQ items here
    {
      question: t('Comment puis-je'),
      answer: t('Toutes nos fiches'),
    },
    {
      question: t("Qu'est-ce que le calculateur"),
      answer: t('Notre outil interactif'),
    },
    {
      question: t('Utilisez-vous des'),
      answer:
        t('Chez VOPA, nous valorisons'),
    },
    {
      question: t('Proposez-vous des options'),
      answer:
        t('Oui, nous prenons au'),
    },
    {
      question: t('Puis-je passer une'),
      answer:
        t('Oui, vous pouvez commander'),
    },

    {
      question: t("Quels sont vos horaires d'ouverture ?"),
      answer:
        t("Nos horaires d'ouverture varient."),
    },
    {
      question: t('Offrez-vous des programmes'),
      answer:
        t('Oui, nous proposons régulièrement'),
    },
    {
      question: t('Comment puis-je prendre'),
      answer:
        t('Pour toute autre question'),
    },
  ];


  useEffect(() => {
    Aos.init({ duration: 3000 });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (

    <>
      <div className="bg-cover bg-center mt-[100px] bg-image">
        <div className="grid justify-items-center relative top-24 ">
          <p className="contact font-bold  text-9xl sm:text-1xl ">{data.title}</p>
        </div>
        <img src={hero1} alt="" className='circle-image ml-11' data-aos="fade-left" />
        <img src={hero2} alt="" className='circle-image ml-96 mt-10' data-aos="fade-left" />
        <div className='flex justify-end mr-11' data-aos="fade-right">
          <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
          <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
        </div>
      </div>
      <div className="">

        <div className="container mx-auto">
          <div className="space-y-4  mt-12 mb-10" >
            {faqData.map((item, index) => (
              <div key={index} className="bg-white p-6 rounded  border duration-700 hover:shadow-2xl hover:duration-700" data-aos="fade-up">
                <h2 className="text-lg font-semibold text-green-600 Inter">{item.question}</h2>
                <p className="text-gray-600 mt-2 kalam">{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value) }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default FAQ;
