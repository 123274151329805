import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const Acceuil = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  });


  const { t } = useTranslation();



  const data = [
    {
      title: t('Nous  croyons'),
      pagha: t('Notre restaurant')
    },

    {
      title: t('Découvrez'),
      pagha: t('Plongez')
    },

    {
      title: t('Calculateur de Calories'),
      pagha: t('Nous comprenons')
    },

    {
      title: t('Engagement'),
      pagha: t('Nous nous engageons')
    },

    {
      title: t('Rejoignez'),
      pagha: t('En tant que client de VOPA')
    },

    {
      title: t('Découvrez nos'),
      pagha: t('Parcourez')
    },


  ]

  return (
    <>
      <div className="py-8 m-10 text-center rounded-xl">
        <div className="container mx-auto p-4 ">
          <h1 className="text-4xl font-bold text-center  mb-4  " style={{ color: "#195A00" }}>
            {t('Bienvenue chez VOPA')}
          </h1>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-6">


            {data.map((i) => (
              <div className="  p-4 font-bold  bg-cover bg-no-repeat justify-center  rounded-xl shadow hover:shadow-lg transition duration-300 transform hover:-translate-y-1 hover:scale-105" data-aos="fade-up">
                <h2 className="text-4xl font-extrabold mb-2 z-20 Montserrat" style={{ color: '#195A00' }}>{i.title}</h2>
                <p className='kalam text-xl'>{i.pagha}</p>
              </div>
            ))}

          </div>
        </div>
      </div >
      <div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value) }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Acceuil;
