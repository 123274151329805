
import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import { gsap } from "gsap";
import styled from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';
import bgWelcome from '../img/Frame3.png'


gsap.registerPlugin(ScrollTrigger);
const AboutPage = () => {


  const { t } = useTranslation();



  const data = {


    title: t('title'),
    title2: t('title2'),
    pagh1: t('pagh1'),


    // Add More Cards 

    // card 1
    cardTitle1: t('Notre Histoire'),
    cardPargha1: t('Fondé sur'),

    // card 2
    cardTitle2: t('Notre Engagement pour la Santé'),
    cardPargha2: t('Chez VOPA'),

    // card 3
    cardTitle3: t('Notre Philosophie Culinaire'),
    cardPargha3: t('Derrière'),

    // card 4
    cardTitle4: t('Notre Équipe'),
    cardPargha4: t('Derrière chaque'),


  }


  useEffect(() => {
    const matches = document.querySelectorAll("p");
    matches.forEach((target) => {
      gsap.to(target, {
        backgroundPositionX: "0%",
        stagger: 9,
        scrollTrigger: {
          trigger: target,
          scrub: true,
          start: "top center",
          end: "bottom 40%",
        },
      });
    });
    return () => { };
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1500 });
  });


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <><div className="mt-28">


      <div className="bg-cover bg-center mt-[100px] bg-image">
        <div className="grid justify-items-center relative top-24 ">
          <p className="contact font-bold  text-7xl sm:text-1xl ">{data.title}</p>
        </div>
        <img src={hero1} alt="" className='circle-image ml-11' data-aos="fade-left" />
        <img src={hero2} alt="" className='circle-image ml-9 mt-10' data-aos="fade-left" />
        <div className='flex justify-end mr-11' data-aos="fade-right">
          <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
          <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
        </div>
      </div>


      <div className="container mx-auto ">
        <div className=' sm:h-[500px] flex  flex-col '>
          <div className='flex justify-center mx-auto flex-col mt-36 '>
            <p className='Montserrat text-4xl text-center color font-bold' data-aos="fade-up">{data.title2}</p>
            <Text>
              <p className='kalam text-2xl mt-6 w-72 sm:w-[80vh] md:w-[100vh] lg:w-[120vh] xl:w-[150vh] 2xl:w-[170vh] mx-auto text-center' data-aos="fade-up">{data.pagh1}{data.pagh2}{data.pagh3}</p>
            </Text>

          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 space-y-5" data-aos="fade-up">

          <div className=" mx-5 p-8 rounded-lg shadow-md  duration-700 hover:shadow-2xl  hover:duration-700">
            <h3 className="text-3xl Inter text-center font-semibold color mb-2">{data.cardTitle1}</h3>
            <p className="text-gray-700 hover:text-green-800 kalam text-lg leading-relaxed">
              {data.cardPargha1}
            </p>
          </div>
          <div className=" mt-8 md:mt-0 mx-5 p-8 rounded-lg shadow-md  duration-700 hover:shadow-2xl  hover:duration-700">
            <h3 className=" text-center font-extrabold  mb-2 text-3xl color Inter">{data.cardTitle2}</h3>
            <p className="text-gray-700 text-lg leading-relaxed hover:text-green-800 kalam">
              {data.cardPargha2}
            </p>
          </div>

          <div className=" mx-5 p-6 rounded-lg shadow-md  duration-700 hover:shadow-2xl  hover:duration-700">
            <h3 className="text-3xl text-center font-semibold  mb-2 color Inter">{data.cardTitle3}</h3>
            <p className="text-gray-700 text-lg leading-relaxed hover:text-green-800 kalam">
              {data.cardPargha3}
            </p>
          </div>

          <div className=" mt-8 md:mt-0 mx-5 p-6 rounded-lg shadow-md  duration-700 hover:shadow-2xl  hover:duration-700">
            <h3 className="text-3xl text-center font-semibold  mb-2 color Inter">{data.cardTitle4}</h3>
            <p className="text-gray-700 text-lg leading-relaxed hover:text-green-800 kalam">
              {data.cardPargha4}
            </p>
          </div>




        </div>

        <div className="mt-12 flex flex-col md:flex-row">

        </div>

        <br />
        <br />
        <br />
      </div>
    </div><div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value); }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div></>
  );
};

export default AboutPage;



const Text = styled.div`
                p {
                    background: linear-gradient(to right,  #195A00 50%, black 50%);
                background-size: 200% 100%;
                background-position-x: 100%;
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
                // margin-left: 50px;
                // margin-top: 20px;
                // margin-bottom: -6px;
                user-select: none;
                line-height:1.5
  }
`;