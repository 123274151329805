import React from "react";

import img1 from '../img/cl1.jpg'
import img2 from '../img/cl2.jpg'
import img3 from '../img/cl3.jpg'
import img4 from '../img/cl4.jpg'
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const Avis = () => {
    const { t } = useTranslation();

    const data = [
        {
            image: img1,
            Name: t('Sophie R.'),
            paragh: t('VOPA Est Devenu'),
        },

        {
            image: img2,
            Name: t('Alexandre T.'),
            paragh: t('En Tant Que Sportif'),
        },

        {
            image: img3,
            Name: t('Laura G.'),
            paragh: t('VOPA A Été'),
        },

        {
            image: img4,
            Name: t('Marc P'),
            paragh: t('En Tant Que'),
        }
    ]


    return (
        <>

            <div className="container mx-auto mt-28">
                <p className="text-center text-5xl Inter" style={{ color: "#195A00" }}>Avis Client</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 space-y-5 mt-4">
                    {data.map((i) => (
                        <div className="shadow-md duration-700 rounded-lg hover:shadow-2xl hover:duration-700 p-6 text-center flex flex-col bg">
                            <div class="image mx-auto h-[95px] w-[95px] p-[3px] rounded-[50%]" style={{ color: '' }}>
                                <img src={i.image} className="h-[100%] w-[100%] rounded-[50%]" alt="" style={{ objectFit: 'cover', border: '2px solid #fff' }} />
                            </div>
                            <i class="fas fa-quote-left quote text-3xl mt-2"></i>
                            <p className="mt-3 text-2xl Inter" style={{ color: "#195A00" }}>{i.Name}</p>
                            <p className="w-68 kalam ">"{i.paragh}"</p>
                        </div>
                    ))}
                </div>
            </div>



            <div className="fixed bottom-10 right-[30px]">
                <div className="relative flr">
                    <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value) }}>
                        <option value="en" className='text-lg'>English</option>
                        <option value="ar" className='text-lg'>Arabic</option>
                        <option value="fr" className='text-lg'>French</option>
                        <option value="sp" className='text-lg'>Espagnol</option>
                    </select>
                </div>
            </div>
        </>



    );
};

export default Avis;
