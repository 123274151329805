import React, { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';

import whiteVopa from '../img/INFO-VOPA VF.svg'
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Navbar() {
    const { t } = useTranslation();

    let Links = [
        { name: t('ACCEUIL'), link: "/" },
        { name: t('A_PROPOS'), link: "/apropos" },
        { name: t('CALORIES'), link: "/calories" },
        { name: t('CONTACT'), link: "/contact" },
        { name: t('MENU'), link: "/menu" }
    ];
    let [open, setOpen] = useState(false);

    return (
        <div>
            <div className='shadow-md w-full fixed top-0 left-0 z-50'>
                <div className='md:flex items-center justify-center lg:space-x-96 bg-white py-4 md:px-10 h-28 px-7'>
                    {/* logo section */}
                    <div className=''>
                        <img src={whiteVopa} class="lg:h-24 w-40 h-20 lg:mr-20" alt="img" />
                    </div>
                    {/* Menu icon */}
                    <div onClick={() => setOpen(!open)} className='absolute right-8 top-11 cursor-pointer md:hidden w-7 h-7'>
                        {
                            open ? <XMarkIcon /> : <Bars3BottomRightIcon />
                        }
                    </div>
                    {/* linke items */}
                    <ul className={`md:flex md:items-center  md:mt-12 pb-6 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-24' : 'top-[-490px]'}`}>
                        {
                            Links.map((link) => (
                                <li onClick={() => setOpen(!open)} className='md:ml-10  md:my-0 my-7 lg:font-bold lg:text-lg Raleway '>
                                    <Link to={link.link} className='text-green-700 no-underline hover:text-green-400 duration-500'>{link.name}</Link>
                                </li>))
                        }
                    </ul>
                    {/* button */}

                </div>

            </div>


        </div>
    )

}

export default Navbar