import React from 'react';

import { Routes, Route } from "react-router-dom";
import Home from './components/Home';
import AboutPage from './components/aPropos';
import FAQ from './components/faq';
import PrivacyPolicy from './components/confidentiale';
import Contact from './components/contact';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Form from './components/form';
import Menuu from './components/Menuu';


import './App.css'


function App() {

  return (

    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/apropos' element={<AboutPage />} />
        <Route path="/question" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/calories" element={<Form />} />
        <Route path="/menu" element={<Menuu />} />

      </Routes>
      <Footer />



    </>
  );
}

export default App;
