import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";

// Importez vos images
import res1 from '../img/res1.png';
import pay from '../img/pay.png';
import res2 from '../img/res2.png';
import res3 from '../img/res3.png';
import res4 from '../img/res4.png';
import pizza from '../img/pizza.png';
import s1 from '../img/s1.png';
import s2 from '../img/s2.png';
import s3 from '../img/s3.png';
import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
// Enregistrez le plugin ScrollTrigger de gsap
gsap.registerPlugin(ScrollTrigger);

const Menu = () => {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios.get("https://api.base-vopa.shop/api/category")
      .then(response => {
        setCategory(response.data); // Set category to response.data
        console.log(response.data); // Optional: Log the response data
      })
      .catch(error => {
        console.error('Error fetching unique cities:', error);
      });
  }, []);

  const { t } = useTranslation();

  const data = {
    title: t('Menu du Restaurant'),
    items: [
      { name: "Plat 1", description: "Description du plat 1", image: res1, reservationLink: "https://vopa.ma/" },
      { name: "Plat 2", description: "Description du plat 2", image: pay, reservationLink: "https://vopa.ma/" },
      { name: "Plat 3", description: "Description du plat 3", image: res4, reservationLink: "https://vopa.ma/" },
      { name: "Plat 4", description: "Description du plat 4", image: res2, reservationLink: "https://vopa.ma/" },
      { name: "Plat 5", description: "Description du plat 5", image: pizza, reservationLink: "https://vopa.ma/" },
      { name: "Plat 6", description: "Description du plat 6", image: res3, reservationLink: "https://vopa.ma/" },
      { name: "Plat 7", description: "Description du plat 7", image: s1, reservationLink: "https://vopa.ma/" },
      { name: "Plat 8", description: "Description du plat 8", image: s3, reservationLink: "https://vopa.ma/" },
      { name: "Plat 9", description: "Description du plat 9", image: s2, reservationLink: "https://vopa.ma/" }

    ]
  };

  // Effets au chargement de la page
  useEffect(() => {
    // Initialisez AOS pour les animations
    Aos.init({ duration: 3000 });

    // Effet de défilement avec gsap
    const matches = document.querySelectorAll("p");
    matches.forEach((target) => {
      gsap.to(target, {
        backgroundPositionX: "0%",
        stagger: 9,
        scrollTrigger: {
          trigger: target,
          scrub: true,
          start: "top center",
          end: "bottom 40%",
        },
      });
    });

    // Retournez une fonction de nettoyage pour le useEffect
    return () => { };
  }, []);

  // Effet pour remonter en haut de la page au chargement
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const ReservationButton = styled.a`
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
  `;

  return (
    <><div className="bg-cover bg-center mt-[100px] bg-image">
      <div className="bg-cover bg-center mt-[100px] bg-image">
        <div className="grid justify-items-center relative top-24 ">
          <p className="contact font-bold  text-4xl sm:text-7xl ">{data.title}</p>
        </div>

        <img src={hero1} alt="" className='circle-image ml-6' data-aos="fade-left" />
        <img src={hero2} alt="" className='circle-image ml-9 mt-10' data-aos="fade-left" />
        <div className='flex justify-end mr-11' data-aos="fade-right">
          <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
          <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
        </div>
      </div>

      <div className="container ">
        <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 space-x-5">
          {category.map((item, index) => (
            <MenuItem key={index} className='w-80 md:w-[89vh] lg:w-[59vh] xl:w-[49vh] 2xl:w-96 mx-auto'>
              <img src={`https://api.base-vopa.shop/storage/product/image/${item.image}`} alt={item.name} className="w-full h-64 object-cover mb-4 rounded-lg shadow-md" />
              <h3 className="text-3xl font-semibold mb-2">{item.name}</h3>
              <p className="text-gray-700">{item.description}</p>
              <ReservationButton href="https://vopa.ma/" target="_blank" rel="noopener noreferrer">{t('Réserver')}</ReservationButton>
            </MenuItem>
          ))}
        </div>
      </div>
    </div><div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value); }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div></>
  );
};

const MenuItem = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  
`;

export default Menu;
