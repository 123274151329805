import React, { useState, useEffect } from 'react';
import Aos from 'aos';


import hero1 from '../img/hero1.png';
import hero2 from '../img/hero2.png';
import hero3 from '../img/hero3.png';

import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const data = {
    title: t('Politique de Confidentialité')
  }


  const chezVoopa = {
    chez: t('Chez VOPA, nous')
  }

  const col_2 = [
    {
      title: t('Collecte de Données'),
      pagha1: t('Lorsque vous'),
      pagha2: t('Nous utilisons'),
    },
  ]

  const col_3 = [
    {
      title: t('Utilisation des Données'),
      pagha1: t('Les informations'),
      pagha2: t('Nous pouvons')
    },
  ]

  const paragha = [
    {
      title: t('Partage des Données'),
      paragha: t('Nous ne vendons')
    },

    {
      title: t('Sécurité des Données'),
      paragha: t('Nous mettons')
    },

    {
      title: t('Conservation des Données'),
      paragha: t('Nous conservons')
    },

    {
      title: t('Droits des Utilisateurs'),
      paragha: t('Vous avez le droit')
    },

    {
      title: t('Modification de la Politique'),
      paragha: t('Nous nous réservons')
    },

    {
      title: t('Contact'),
      paragha: t('si vous avez')
    },


  ]

  useEffect(() => {
    Aos.init({ duration: 3000 });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  return (

    <>

      <div className="bg-cover bg-center mt-[100px] bg-image">
        <div className="grid justify-items-center relative top-24 ">
          <p className="contact font-bold  text-6xl sm:text-1xl ">{data.title}</p>
        </div>
        <img src={hero1} alt="" className='circle-image ml-11' data-aos="fade-left" />
        <img src={hero2} alt="" className='circle-image ml-9 mt-10' data-aos="fade-left" />
        <div className='flex justify-end mr-11' data-aos="fade-right">
          <img src={hero2} alt="" className='circle-image ' data-aos="fade-right" />
          <img src={hero3} alt="" className='circle-image ml-11' data-aos="fade-left" />
        </div>
      </div>



      <div className="py-10 mt-28">
        <div className="container  mx-auto ">
          <div className="grid grid-cols-1 gap-6 ">

            <div className="bg-white p-6 rounded-lg border text-lg duration-700 hover:shadow-2xl hover:duration-700">
              <p className='kalam'>
                {chezVoopa.chez}
              </p>
            </div>


            <div className="bg-white p-6 rounded  border duration-700 hover:shadow-2xl hover:duration-700" >
              {col_2.map((item, index) => (
                <React.Fragment key={index}>
                  <h2 className="text-xl text-green-600 font-bold mt-4">{item.title}</h2>
                  <p className='kalam text-lg'>
                    {item.pagha1}
                  </p>
                  <p className='kalam text-lg'>
                    {item.pagha2}
                  </p>
                </React.Fragment>
              ))}
            </div>


            <div className="bg-white p-6 rounded  border duration-700 hover:shadow-2xl hover:duration-700" >
              {col_3.map((item, index) => (
                <React.Fragment key={index}>
                  <h2 className="text-xl text-green-600 font-bold mt-4">{item.title}</h2>
                  <p className='kalam text-lg'>
                    {item.pagha1}
                  </p>
                  <p className='kalam text-lg'>
                    {item.pagha2}
                  </p>
                </React.Fragment>
              ))}
            </div>



            {paragha.map((item) => (
              <div className="bg-white p-6 rounded-lg shadow-md border duration-700 hover:shadow-2xl hover:duration-700">
                <h2 className="text-lg font-bold mt-4 text-green-600">{item.title}</h2>
                <p className='kalam text-lg'>
                  {item.paragha}
                </p>
              </div>

            ))}


          </div>
        </div>
      </div>


      <div className="fixed bottom-10 right-[30px]">
        <div className="relative flr">
          <select className='w-32 h-9 border-2 rounded-md border-lime-600 focus:bg-lime-600 focus:text-white-A700' onChange={(e) => { i18n.changeLanguage(e.target.value) }}>
            <option value="en" className='text-lg'>English</option>
            <option value="ar" className='text-lg'>Arabic</option>
            <option value="fr" className='text-lg'>French</option>
            <option value="sp" className='text-lg'>Espagnol</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
